import React from "react"
import { graphql, StaticQuery } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HalfHeader from "../components/halfBGImage"
import { LocalizedLink as Link } from "gatsby-theme-i18n"
import "../utils/normalize.css"
import "../utils/css/screen.css"
import { useIntl } from "react-intl"

const ElementsPage = ({ data }, props) => {
  const siteTitle = data.site.siteMetadata.title
  const intl = useIntl()

  return (
    <Layout location={props.location} title={siteTitle}>
      <SEO
        title="Sorry!"
        keywords={[`pianist`, `prodigy`, `classical musician`, `piano`]}
      />

      <HalfHeader data={data} title={intl.formatMessage({ id: "404" })} />

      <article className="post-content page-template no-image">
        <div className="post-content-body">
          <h2 id="this-is-a-section">{intl.formatMessage({ id: "404" })}</h2>
          <hr />
          <h3>
            <Link to="/">{intl.formatMessage({ id: "main" })}</Link>
          </h3>
        </div>
      </article>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    mobileImage: file(relativePath: { eq: "ilyun-burkev-desktop-gallery-2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 490) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    desktopImage: file(relativePath: { eq: "ilyun-burkev-desktop-gallery-2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <ElementsPage location={props.location} data={data} {...props} />
    )}
  />
)
